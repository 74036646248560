import { HomePage as DSHomePage } from '@artegeie/design-system/replay';
import { type ReactElement } from 'react';

import { getAlternativeLanguages, getMeta, getPage, type Page } from '@replay/types/Page';
import { type Domain } from '@replay/types/MyGetServerSidePropsResult';
import { Metas } from '@/shared/metas/Metas';
import { DeepLink } from '@/shared/metas/DeepLink';
import { JsonLdOrganisation, JsonLdWebSite } from '@/shared/metas/JsonLd';

type Props = {
    data: Page | null | undefined;
    domain: Domain;
};

export const HomePage = ({ data, domain }: Props): ReactElement | null => {
    if (!data) {
        return null;
    }
    const alternativeLanguages = getAlternativeLanguages(data);
    const meta = getMeta(data);
    const page = getPage(data);

    return (
        <>
            <Metas
                title={meta.title}
                description={meta.description}
                url={data.url}
                language={data.language}
                alternativeLanguages={alternativeLanguages}
                meta={meta}
            />
            <DeepLink page={page} url={data.url} type="emac" />
            <JsonLdWebSite key="jsonld-website" language={data.language} url={data.url} />
            <JsonLdOrganisation key="jsonld-organisation" language={data.language} url={data.url} />

            {data && <DSHomePage page={data} domain={domain} />}
        </>
    );
};
