import { type Mode } from '@artegeie/arte-vp';
import type { AlternativeLanguage, Page as DsPage } from '@artegeie/design-system/type';
import { type Locale } from './Locale';
import { type Meta, type MetaV4, metaV4ToMeta } from './Meta';
import { type Stats } from './Stats';
import { type Zone } from './Zone';

export const PAGE_TYPE_COLLECTIONS = 'collections';
export const PAGE_TYPE_PROGRAMS = 'programs';
export const PAGE_TYPE_PROGRAMS_TRAILER = 'trailer';
export const PAGE_TYPE_GENRES = 'genres';
export const PAGE_TYPE_CATEGORIES = 'categories';
export const PAGE_TYPE_SUBCATEGORIES = 'subcategories';
export const PAGE_TYPE_LISTS = 'lists';
export const PAGE_TYPE_ACT_WITH_EBU_BOX = 'act_with_ebu_box';
export const PAGE_TYPE_UNKNOWN = 'page_unknown';

export type Page = DsPage & {
    language: Locale;
    slug: string;
    seo?: { title: string };
    metadata: MetaV4;
    stats?: Stats;
    alternativeLanguages: Array<AlternativeLanguage> | null;
    parent?: DsPage['parent'] & {
        type: string;
    };
    deeplink: string;
};

export const getZones = (page: Page): Zone[] => page.zones;

export const getAlternativeLanguages = (page?: Page): AlternativeLanguage[] | null => {
    return page?.alternativeLanguages ?? null;
};

export const transformAlternativeLanguages = (
    page: Page,
    transformer: (alternativeLanguage: AlternativeLanguage[]) => AlternativeLanguage[],
): Page => {
    const alternativeLanguages = getAlternativeLanguages(page);
    if (alternativeLanguages) {
        const transformedAlternativeLanguages = transformer(alternativeLanguages);
        return {
            ...page,
            alternativeLanguages: transformedAlternativeLanguages,
        };
    }
    return page;
};

export const getMeta = (page: Page): Meta => {
    return metaV4ToMeta(page.metadata);
};

export const getPage = (page: Page): string => {
    return page.code;
};

export type PageType =
    | typeof PAGE_TYPE_COLLECTIONS
    | typeof PAGE_TYPE_PROGRAMS
    | typeof PAGE_TYPE_PROGRAMS_TRAILER
    | typeof PAGE_TYPE_GENRES
    | typeof PAGE_TYPE_CATEGORIES
    | typeof PAGE_TYPE_SUBCATEGORIES
    | typeof PAGE_TYPE_LISTS
    | typeof PAGE_TYPE_ACT_WITH_EBU_BOX
    | typeof PAGE_TYPE_UNKNOWN;

export const getPlayerModeFromPageType = (pageType: PageType): Mode | null => {
    if (pageType === PAGE_TYPE_PROGRAMS) {
        return 'default';
    }
    if (pageType === PAGE_TYPE_PROGRAMS_TRAILER) {
        return 'trailer';
    }
    return null;
};
