import { stringify } from 'query-string';
import useSWR, { type Fetcher, type SWRConfiguration, type SWRResponse } from 'swr';
import { type ConcertSubCategoryCode, type ConcertCode } from '../domains/concert/ConcertCode';
import { type Locale } from '@replay/i18n/types/locale';
import { type Page } from '@replay/types/Page';
import { isOk } from '@replay/types/Result';
import { type ReplayError } from '@replay/types/Type_Error';
import { fetchEmacPage } from './emac/commons';
import { type ResultFetchPageData } from '@replay/types/MyGetServerSidePropsResult';

export enum PageType {
    HOME = 'HOME',
    CONCERT = 'ARTE_CONCERT',
    DIRECT = 'LIVE',
    TV_GUIDE = 'TV_GUIDE',
    SEARCH = 'SEARCH',
    SEARCH_HOME = 'SEARCH_HOME',
    MY_RESUME = 'MY_RESUME',
    MY_FAVORITES = 'MY_FAVORITES',
    MY_HISTORY = 'MY_HISTORY',
    MY_VIDEOS = 'MY_VIDEOS',
    FOR_ME = 'FOR_ME',
}

type FetchPageType = PageType | ConcertCode | string | null;

type ParamType = Record<string, string | number | undefined>;

type PageKind = 'pages' | 'pages-by-slug';

export type FetcherProps = {
    abvGroups: string | null;
    endpoint: PageType | ConcertCode | string | null;
    geoblocking: string | null;
    locale: Locale | null;
    params: ParamType | null;
    type?: PageKind;
    transformData?: (page: Page) => Page;
};

export const makeSwrConfig = <D>(
    pageType: PageType | ConcertCode | ConcertSubCategoryCode,
    initialPage: D,
): SWRConfiguration<D> => {
    const dynamicConfig: SWRConfiguration<D> = {
        fallbackData: initialPage,
        loadingTimeout: 2000,
        revalidateOnMount: false,
        // Revalidate every 5 minutes on focus
        focusThrottleInterval: 5 * 60 * 1000,
        // Refresh every 5 minutes
        refreshInterval: 5 * 60 * 1000,
        // cache data for 3 minutes
        dedupingInterval: 3 * 60 * 1000,
        // keep previous data while fetching, to avoid flickering of initial data
        keepPreviousData: true,
    };
    const staticConfig: SWRConfiguration<D> = {
        fallbackData: initialPage,
        revalidateOnMount: false,
        revalidateOnFocus: false,
    };

    switch (pageType) {
        case PageType.TV_GUIDE:
        case PageType.DIRECT:
            return dynamicConfig;
        default:
            return staticConfig;
    }
};

export const ensurePreviewDate = (previewDate?: string | string[] | undefined): string | undefined => {
    return Array.isArray(previewDate) ? previewDate[0] : previewDate;
};

export const ensureDateParam = (previewDate: Date | undefined): Record<'previewDate', Date> | undefined => {
    if (!previewDate) return undefined;
    return { previewDate };
};

export const fetchPageData: Fetcher<ResultFetchPageData, FetcherProps> = async ({
    endpoint,
    locale,
    params,
    geoblocking,
    abvGroups,
    transformData,
}) => {
    const stringifiedParams = params ? stringify(params, { skipNull: true }) : '';
    const ensuredEndpoint = endpoint?.replace(/(\/|\?)?$/, '/') ?? '';

    const data = await fetchEmacPage({
        url: `/api/emac/v4/${locale}/web/pages/${ensuredEndpoint}${stringifiedParams ? `?${stringifiedParams}` : ''}`,
        geoblocking,
        abvGroups,
    });

    if (transformData && isOk<Page, ReplayError>(data)) {
        return {
            ...data,
            data: transformData(data.value),
        };
    }

    return data;
};

type UsePageDataProps = Omit<FetcherProps, 'endpoint'>;
export const usePageData = (
    endpoint: FetchPageType,
    { abvGroups, geoblocking, locale, params, type }: UsePageDataProps,
    swrConfig: SWRConfiguration,
): SWRResponse<ResultFetchPageData, Error> => {
    const result = useSWR<ResultFetchPageData, Error, FetcherProps>(
        {
            abvGroups,
            endpoint,
            geoblocking,
            locale,
            params,
            type,
        },
        fetchPageData,
        swrConfig,
    );

    return result;
};
