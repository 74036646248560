import { type ReactElement } from 'react';

import newrelic from 'newrelic';
import { type GetServerSidePropsContext } from 'next';
import { useRouter } from 'next/router';
import { HomePage } from '../domains/home/HomePage';
import { getAbGroupsFromRequest } from '@/shared/abTesting/abGroupParser';
import { Error } from '@/shared/Error';
import { ensurePreviewDate, fetchPageData, makeSwrConfig, PageType, usePageData } from '@/shared/fetchPageData';
import getGeoblockingFromRequest from '@/shared/getGeoblockingFromRequest';
import { type Locale } from '@replay/i18n/types/locale';
import {
    type EmacServerSideProps,
    type InferPageProps,
    resolveDomainWithPage,
} from '@replay/types/MyGetServerSidePropsResult';
import { isError } from '@replay/types/Result';
import { getHttpStatusCode } from '@replay/types/Type_Error';

type Props = {
    geoblocking: string | null;
    abvGroups: string;
};

const noDataError = (locale: Locale): GlobalError['HomePage'] => ({
    type: 'HomePage',
    err: {
        type: 'NoData',
        locale: locale,
    },
});

export const getServerSideProps = async ({
    locale,
    req,
    query,
    res,
    resolvedUrl,
}: GetServerSidePropsContext): Promise<EmacServerSideProps<Props>> => {
    newrelic.setTransactionName('HomePage');

    const geoblocking = getGeoblockingFromRequest(req);
    const abvGroups = getAbGroupsFromRequest(req);
    const initialPage = await fetchPageData({
        endpoint: PageType.HOME,
        locale: locale as Locale,
        params: { previewDate: ensurePreviewDate(query?.previewDate) },
        geoblocking,
        abvGroups,
    });

    const domain = resolveDomainWithPage(resolvedUrl, initialPage);

    res.statusCode = getHttpStatusCode(initialPage);

    return {
        props: {
            type: 'emac',
            code: 'HOME',
            locale: locale as Locale,
            domain,
            props: {
                geoblocking,
                abvGroups: abvGroups || '',
                page: initialPage,
            },
        },
    };
};

const Home = ({ props, locale, domain }: InferPageProps<typeof getServerSideProps>): ReactElement => {
    const { previewDate } = useRouter().query;
    const { data } = usePageData(
        PageType.HOME,
        {
            geoblocking: props.geoblocking,
            abvGroups: props.abvGroups,
            locale,
            params: { previewDate: ensurePreviewDate(previewDate) },
        },
        makeSwrConfig(PageType.HOME, props.page),
    );

    if (!data || isError(data)) {
        if (isError(props.page)) {
            return <Error error={data?.value || noDataError(locale)} />;
        }
        return <HomePage data={props.page.value} domain={domain} />;
    }

    return <HomePage data={data.value} domain={domain} />;
};

export default Home;
